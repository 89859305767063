import authorOne from "./images/author-1.jpg";
import authorTwo from "./images/author-2.jpg";
import authorThree from "./images/author-3.jpg";
import logo192 from "./images/logo192.png";

interface ServiceOffered {
    title: string,
    description: string,
    iconClass: string,
    link: string
}

interface NavbarInfo {
    brandTitle: string,
    navItems: Array<{
        title: string,
        link: string,
    }>
}

interface Testimonial {
    testimonialImage: any,
    testimonialInfo: {
        title: string,
        description: string
    }
}

interface SocialLink {
    link: string,
    className: string
}

interface WebsiteData {
    logo192: any,
    navbarInfo: NavbarInfo,
    about: {
        companyDescription: string,
        description: string
    },
    servicesOffered: Array<ServiceOffered>,
    testimonials: Array<Testimonial>,
    socialLinks: Array<SocialLink>
}

const navbarSectionInfo: NavbarInfo = {
    brandTitle: "Samarth Investment & Solutions",
    navItems: [
        {title: "Home", link: "#"},
        {title: "Services", link: "#service"},
        {title: "About", link: "#about"},
        // {title: "Testimonial", link: "#testimonial"},
        {title: "Contact", link: "#contact"},
    ]
}

const companyDescription = "Samarth Investment & Solutions is a premier financial advisory firm dedicated to providing bespoke financial strategies and solutions. " +
    "With a strong focus on individual and family financial planning, our services are tailored to meet the unique needs and goals of each client. " +
    "Led by Ashish Shrivastava, a Mutual Funds Advisor, our team brings expertise and a client-centered approach to investment management, " +
    "risk management through comprehensive life insurance plans, and wealth accumulation for a comfortable retirement. " +
    "We are committed to excellence, integrity, and building long-term relationships with our clients by offering personalized advice, meticulous planning, and a commitment to their financial well-being. " +
    "At Samarth Investment & Solutions, your financial future is in skilled and caring hands";

const servicesOffered: Array<ServiceOffered> = [
    {
        title: "Mutual Funds & Investment Strategies",
        description: "I help clients select and manage mutual funds aligned with their risk tolerance and financial goals.",
        iconClass: "flaticon-line-chart",
        link: "#"
    },
    {
        title: "Life Insurance & Risk Management",
        description: "I help families protect their loved ones with comprehensive life insurance plans and risk management strategies.",
        iconClass: "flaticon-support",
        link: "#"
    },
    {
        title: "Retirement Planning & Wealth Management",
        description: "I help individuals plan for a comfortable retirement through personalized wealth management strategies.",
        iconClass: "flaticon-money",
        link: "#"
    }
]

const testimonials: Array<Testimonial> = [
    {
        testimonialImage: authorOne,
        testimonialInfo: {
            title: "Amanda Friend - CEO - Company",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        }
    },
    {
        testimonialImage: authorTwo,
        testimonialInfo: {
            title: "Sweta Kamdar - CEO - Smart Solutions",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        }
    },
    {
        testimonialImage: authorThree,
        testimonialInfo: {
            title: "Rehana Jesica - CEO - Amrit Solutions",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        }
    }
];

const socialLinks: Array<SocialLink> = [
    {
        link: "https://www.facebook.com/varun.shrivastava.3",
        className: "fa-brands fa-facebook-f"
    },
    {
        link: "https://www.twitter.com/vs_shrivastava",
        className: "fa-brands fa-x-twitter"
    }
];

const websiteData: WebsiteData = {
    logo192: logo192,
    navbarInfo: navbarSectionInfo,
    about: {
        description: "",
        companyDescription: companyDescription,
    },
    servicesOffered: servicesOffered,
    testimonials: [],
    socialLinks: socialLinks
}

export type {
    ServiceOffered, NavbarInfo, WebsiteData
}
export {
    websiteData
}