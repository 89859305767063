import investorDp from "../../images/investor-dp.jpg";
import "../../css/custom.css"
import "../../css/magnific-popup.css"

const FeaturesView = () => {
    return (
        <section className="features" id="about">
            <div className="container">
                <div className="row no-gutters-responsive">
                    <div className="col-md-6">
                        <div className="feature-image">
                            <img className={"img"} src={investorDp}/>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="feature-description">
                            <h2>15+ year of experience as finance adviser</h2>

                            <div className="feature-single">
                                <div className="icon-box">
                                    <i className="flaticon-team"></i>
                                </div>

                                <h3>Experience Team</h3>
                                <p>We work in an atmosphere of trust and camaraderie, where partners help each
                                    other.</p>
                            </div>
                            <div className="feature-single">
                                <div className="icon-box">
                                    <i className="flaticon-support"></i>
                                </div>

                                <h3>Superior Service</h3>
                                <p>We work in an atmosphere of trust and camaraderie, where partners help each
                                    other.</p>
                            </div>
                            <div className="feature-single">
                                <div className="icon-box">
                                    <i className="flaticon-money"></i>
                                </div>

                                <h3>Affordable Pricing</h3>
                                <p>We work in an atmosphere of trust and camaraderie, where partners help each
                                    other.</p>
                            </div>

                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href="#" className="btn-contact">Contact us Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeaturesView;