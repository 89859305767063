import aboutImg from "../../images/about.jpg"
import "../../css/custom.css"
import "../../css/flaticon.css"
const BannerView = () => {
    return (
        <section className="banner" id="home">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="about-company">
                            <div className="row no-gutters">
                                <div className="col-md-8">
                                    <div className="about-image">
                                        <img src={aboutImg} alt="Ashish Shrivastava - Mutual Fund Advisor"/>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="about-description">
                                        <h2>Ashish Shrivastava - MFA</h2>
                                        <p>
                                            I help individuals and families achieve their financial goals through comprehensive
                                            financial planning, investment strategies, and personalized advice.
                                        </p>
                                        <a href="https://www.youtube.com/watch?v=..." className="btn-video popup-video">
                                            <i className="flaticon-play-button"></i> Watch My Introduction
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BannerView;