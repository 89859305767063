import React from 'react';
import logo from './logo.svg';
import './App.css';
import HeaderView from "./components/header/header.view";
import BannerView from "./components/banner/banner.view";
import ServicesView from "./components/services/services.view";
import FeaturesView from "./components/features/features.view";
import TestimonialView from "./components/testimonial/testimonial.view";
import RequestCallbackFormView from "./components/request-callback-form/request-callback-form.view";
import ContactInfoView from "./components/contact-info/contact-info.view";
import FooterView from "./components/footer/footer.view";

function App() {
    return (
        <>
            <HeaderView></HeaderView>
            <BannerView></BannerView>
            <ServicesView></ServicesView>
            <FeaturesView></FeaturesView>
            <TestimonialView></TestimonialView>
            <RequestCallbackFormView></RequestCallbackFormView>
            <ContactInfoView></ContactInfoView>
            <FooterView></FooterView>
        </>
    );
}

export default App;
