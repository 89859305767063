import {websiteData} from "../../portfolio-data";

const HeaderView = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        {websiteData.navbarInfo.brandTitle}
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            {websiteData.navbarInfo.navItems.map((item, index) => {
                                return <li key={index} className="nav-item"><a className="nav-link" href={item.link}>{item.title}</a></li>
                            })}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default HeaderView;