import {FunctionComponent} from "react";
import "../../css/custom.css"

const RequestCallbackFormView:FunctionComponent = () => {
    return <section className="request-callback" id="contact">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="main-title">
                        <h2>Request Call Back</h2>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="request-form">
                        <form role="form">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Full Name"/>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Email"/>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Phone"/>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Subject"/>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <textarea className="form-control" placeholder="Message"></textarea>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="submit" className="btn-request" value="Send Request Now"/>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default RequestCallbackFormView;