import authorOne from "../../images/author-1.jpg"
import authorTwo from "../../images/author-2.jpg"
import authorThree from "../../images/author-3.jpg"
import {useState} from "react";
import {websiteData} from "../../portfolio-data";

interface Item {
    testimonialImage: any,
    testimonialInfo: {
        title: string,
        description: string
    }
}
const TestimonialView = () => {

    if (websiteData.testimonials && websiteData.testimonials.length > 0) {
        return (
            <section className="testimonial" id="testimonial">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-title">
                                <h2>Our Prestigious Clients Love Us</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <ol className="list-group list-group-flush">
                                {
                                    websiteData.testimonials.map((item, index) => {
                                        return <li className="list-group-item d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{item.testimonialInfo.title}</div>
                                                {item.testimonialInfo.description}
                                            </div>
                                            <span>
                                            <img className={"img img-thumbnail"} style={{width: 150}} src={item.testimonialImage} alt={item.testimonialInfo.title}/>
                                        </span>
                                        </li>
                                    })
                                }
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return <></>;
}

export default TestimonialView;