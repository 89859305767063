import {FunctionComponent} from "react";

const ContactInfoView: FunctionComponent = () => {
    return <section className="contact-information">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <div className="contact-single">
                        <p><i className="fa fa-envelope"></i> ashishshri80@gmail.com</p>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="contact-single">
                        <p><i className="fa fa-phone"></i> +91 9977 27 7344</p>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="contact-single">
                        <p><i className="fa fa-map-marker"></i> Jabalpur</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default ContactInfoView;