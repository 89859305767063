import {websiteData} from "../../portfolio-data";

const ServicesView = () => {
    return (
        <section className="services" id="service">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-title">
                            <h2>Tailored Financial Solutions</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {
                        websiteData.servicesOffered.map((item, index) => {
                            return <div className="col-md-4" key={index}>
                                <div className="service-single">
                                    <div className="icon-box">
                                        <i className={item.iconClass}></i>
                                    </div>
                                    <h3>{item.title}</h3>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </section>
    );
}

export default ServicesView;