import {FunctionComponent} from "react";
import logo from "../../images/logo.png"
import {websiteData} from "../../portfolio-data";
import paymentQrCode from "../../images/payment_qr_code.png";

const FooterView: FunctionComponent = () => {
    return <footer>
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <div className="footer-about">
                        <img src={websiteData.logo192}/>

                        <p>{websiteData.about.companyDescription}</p>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="footer-link">
                        <h2>Our Services</h2>
                        <ul>
                            {websiteData.servicesOffered.map((item, index) => {
                                return <li>
                                    <a href={item.link}>{item.title}</a>
                                </li>
                            })}
                        </ul>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="footer-link">
                        {/*<h2>Newsletter</h2>*/}
                        {/*<div className="newsletter">*/}
                        {/*    <p>Get Latest Update & Offers</p>*/}
                        {/*    <form role="form">*/}
                        {/*        <div className="input-group col-md-12">*/}
                        {/*            <input type="text" className="search-query form-control"*/}
                        {/*                   placeholder="Enter Your email address"/>*/}
                        {/*            <span className="input-group-btn">*/}
						{/*				<button type="button">*/}
						{/*					<span className="fa fa-envelope"></span>*/}
						{/*				</button>*/}
						{/*			</span>*/}
                        {/*        </div>*/}
                        {/*    </form>*/}
                        {/*</div>*/}
                        <div className="footer-social">
                            {
                                websiteData.socialLinks.map(item => (
                                    <a href={item.link}><i className={item.className}></i></a>
                                ))
                            }
                        </div>
                    </div>
                    <br/>
                    <div className="row footer-link">
                        <div className="col">
                            <h2>To make a payment scan the QR Code below</h2>
                            <img src={paymentQrCode} alt="payment qr code" className="img img-thumbnail"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="siteinfo">
                        <p>Copyright &copy; Untitled. All rights reserved. Design By <a
                            href="https://www.bemyaficionado.com/" target="_blank">Innomight Themes</a> Images <a
                            href="https://unsplash.com/" target="_blank">Unsplash</a>, <a href="https://pixabay.com/"
                                                                                          target="_blank">Pixabay</a>,
                            <a href="http://www.freepik.com" target="_blank">Freepik</a>, Icon <a
                                href="https://www.flaticon.com/" target="_blank">Flaticon</a></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}

export default FooterView;